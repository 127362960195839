import React, { useRef } from "react"
import styled from "styled-components"
import { useScript } from "../../hooks/useScript"
import { useVisibility } from "../../hooks/useVisibility"
import { ChipList, StyledChipList } from "../Chip/ChipList"
import SocialShare from "../SocialShare/SocialShare"

const StyledArticleFooter = styled.div`
  margin: 2rem 0;
  padding: 2rem 0;
  border-bottom: 2px solid #e8e8ed;
  border-top: 2px solid #e8e8ed;

  + ${StyledChipList} {
    margin: 2rem 0;
  }
`

function ArticleFooter({ title, tags, location: { href } }) {
  const node = useRef()
  const isVisible = useVisibility(node, 150)

  useScript("https://fast.wistia.com/assets/external/E-v1.js", isVisible, true)
  useScript(
    "https://fast.wistia.com/embed/medias/0llvgthdpw.jsonp?idType=ab-test",
    isVisible,
    true
  )

  return (
    <>
      <StyledArticleFooter ref={node}>
        <h2>What Is Actually In Your Dog's Food?</h2>
        <p>
          <strong>WAIT! BEFORE YOU GO</strong> on about your day, ask yourself:
          Is the dog food you're feeding your best friend really the best food
          out there? At its core, there’s an unhealthy meat dependency in pet
          food. Most of the time, meat in your pet food means: Bad ingredients.
          Bad practices. And bad health.{" "}
          <a href="https://wildearth.com?source=blog&medium=footer">
            Learn more about clean protein dog food...
          </a>
        </p>
        <div
          className="wistia_responsive_padding"
          style={{ padding: "56.25% 0 0 0", position: "relative" }}
        >
          <div
            className="wistia_responsive_wrapper"
            style={{
              height: "100%",
              left: 0,
              position: "absolute",
              top: 0,
              width: "100%",
            }}
          >
            <div
              className="wistia_embed wistia_async_0llvgthdpw idType=ab-test seo=false videoFoam=true"
              style={{ height: "100%", position: "relative", width: "100%" }}
            >
              &nbsp;
            </div>
          </div>
        </div>
      </StyledArticleFooter>
      <ChipList labels={tags} />
      <SocialShare title={title} href={href} />
    </>
  )
}

export default ArticleFooter
