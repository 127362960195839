import { graphql } from "gatsby"
import * as React from "react"
import Article from "../components/Article/Article"
import Seo from "../components/seo"
import PostLayout from "../layouts/PostLayout"

function Post({ data: { post }, location }) {
  return (
    <PostLayout>
      <Seo
        title={post.seo.title}
        description={post.seo.metaDesc}
        twitterTitle={post.seo.twitterTitle}
        twitterDescription={post.seo.twitterDescription}
        twitterImage={post.seo.twitterImage}
        opengraphTitle={post.seo.opengraphTitle}
        opengraphDescription={post.seo.opengraphDescription}
        opengraphType={post.seo.opengraphType}
        opengraphImage={post.seo.opengraphImage}
        schema={post.seo.schema}
        metaRobotsNoindex={post.seo.metaRobotsNoindex}
        metaRobotsNofollow={post.seo.metaRobotsNofollow}
        canonical={post.seo.canonical}
      />
      <Article
        featuredImage={post.featuredImage}
        title={post.title}
        content={post.content}
        tags={post.tags}
        location={location}
      />
    </PostLayout>
  )
}

export default Post

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      seo {
        canonical
        title
        metaDesc
        metaRobotsNofollow
        metaRobotsNoindex
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
        opengraphType
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
        schema {
          raw
        }
      }
      id
      title
      date(formatString: "MMMM DD, YYYY")
      content
      tags {
        nodes {
          id
          name
          uri
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
  }
`
