import PropTypes from "prop-types"
import React from "react"
import {
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share"
import styled from "styled-components"

const StyledSocialShareWrapper = styled.div`
  display: flex;
  align-items: center;
  button:not(:first-of-type) {
    margin-left: 0.5em;
  }

  svg {
    display: block;
  }
`

function SocialShare({ href, title }) {
  return (
    <StyledSocialShareWrapper>
      <FacebookShareButton
        url={href}
        quote={title}
        aria-label="Share on Facebook"
      >
        <FacebookIcon size={32} round />
      </FacebookShareButton>
      <TwitterShareButton
        url={href}
        title={title}
        aria-label="Share on Twitter"
      >
        <TwitterIcon size={32} round />
      </TwitterShareButton>
      <RedditShareButton url={href} title={title} aria-label="Share on Reddit">
        <RedditIcon size={32} round />
      </RedditShareButton>
    </StyledSocialShareWrapper>
  )
}

SocialShare.propTypes = {
  href: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default SocialShare
