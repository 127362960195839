import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import styled from "styled-components"
import SocialShare from "../SocialShare/SocialShare"

export const StyledArticleTitle = styled.h1`
  margin: 0.5em 0;
`

function ArticleHeader({ title, featuredImage, location: { href } }) {
  const image = getImage(featuredImage?.node?.localFile)

  return (
    <>
      {image && (
        <GatsbyImage image={image} alt={featuredImage?.node?.altText} />
      )}
      <StyledArticleTitle>{title}</StyledArticleTitle>
      <SocialShare title={title} href={href} />
    </>
  )
}

export default ArticleHeader
