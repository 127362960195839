import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import { parseHtml } from "../../utils/parseHtml"
import ArticleFooter from "./ArticleFooter"
import ArticleHeader from "./ArticleHeader"

export const StyledArticle = styled.article`
  margin: 60px auto;
  max-width: 730px;
  width: 100%;
`

function Article({ featuredImage, title, content, location, tags }) {
  return (
    <StyledArticle>
      <ArticleHeader
        featuredImage={featuredImage}
        title={title}
        location={location}
      />
      <div>{parseHtml(content)}</div>
      <ArticleFooter title={title} location={location} tags={tags.nodes} />
    </StyledArticle>
  )
}

Article.defaultProps = {
  title: "",
  content: "",
}

Article.propTypes = {
  featuredImage: PropTypes.shape({
    node: PropTypes.shape({
      localFile: PropTypes.object,
      altText: PropTypes.string,
    }),
  }),
  location: PropTypes.object,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
}

export default Article
