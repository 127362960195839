import PropTypes from "prop-types"
import * as React from "react"
import styled from "styled-components"
import SiteLayout from "./SiteLayout"

const PostWrapper = styled.div`
  padding: 0 30px;
  margin: 0 auto;
`

const PostLayout = ({ children }) => {
  return (
    <SiteLayout>
      <PostWrapper>
        <main>{children}</main>
      </PostWrapper>
    </SiteLayout>
  )
}

PostLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PostLayout
